'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _jsx = function () { var REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol.for && Symbol.for("react.element") || 0xeac7; return function createRawReactElement(type, props, key, children) { var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = {}; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : '' + key, ref: null, props: props, _owner: null }; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n\n  ', ';\n'], ['\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n\n  ', ';\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _controls = require('./controls');

var _wrap = require('../wrap');

var _wrap2 = _interopRequireDefault(_wrap);

var _utils = require('../../../../utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var Video = _styledComponents2.default.video(_templateObject, function (_ref) {
  var autoPlay = _ref.autoPlay;
  return autoPlay && '\n    &::media-controls-start-playback-button {\n      display: none;\n      appearance: none;\n    }\n  ';
});

var CardVideo = function (_Component) {
  _inherits(CardVideo, _Component);

  function CardVideo(props) {
    _classCallCheck(this, CardVideo);

    var _this = _possibleConstructorReturn(this, (CardVideo.__proto__ || Object.getPrototypeOf(CardVideo)).call(this, props));

    _this.togglePlayback = function (event) {
      if (_this.props.controls) {
        event.preventDefault();
        _this.setState(function (_ref2) {
          var playing = _ref2.playing;

          var action = !playing ? 'play' : 'pause';
          _this.video[action]();
          return { playing: !playing };
        });
      }
    };

    _this.updateProgress = function () {
      var progress = _this.video.currentTime / _this.video.duration * 100;
      _this.setState({ progress: progress });
    };

    _this.state = {
      playing: props.autoPlay,
      progress: 0
    };
    return _this;
  }

  _createClass(CardVideo, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          autoPlay = _props.autoPlay,
          cardSize = _props.cardSize,
          controls = _props.controls,
          image = _props.image,
          loading = _props.loading,
          loop = _props.loop,
          muted = _props.muted,
          playsInline = _props.playsInline,
          video = _props.video,
          props = _objectWithoutProperties(_props, ['autoPlay', 'cardSize', 'controls', 'image', 'loading', 'loop', 'muted', 'playsInline', 'video']);

      var _state = this.state,
          playing = _state.playing,
          progress = _state.progress;


      return _react2.default.createElement(
        _wrap2.default,
        _extends({
          className: 'microlink_card__media_video_wrapper',
          cardSize: cardSize,
          loading: loading,
          onClick: this.togglePlayback
        }, props),
        _react2.default.createElement(Video, _extends({
          className: 'microlink_card__media microlink_card__media_video',
          src: (0, _utils.getUrlPath)(video),
          poster: (0, _utils.imageProxy)(image),
          muted: muted,
          autoPlay: autoPlay,
          loop: loop,
          playsInline: playsInline,
          ref: function ref(node) {
            return _this2.video = node;
          }
        }, controls ? { onTimeUpdate: this.updateProgress } : {})),
        _jsx(_controls.PlayButton, {
          cardSize: cardSize,
          visible: controls && !playing
        }),
        controls && _jsx(_controls.ProgressBar, {
          cardSize: cardSize,
          progress: progress,
          playing: playing
        })
      );
    }
  }]);

  return CardVideo;
}(_react.Component);

exports.default = CardVideo;