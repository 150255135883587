'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProgressBar = exports.PlayButton = undefined;

var _templateObject = _taggedTemplateLiteral(['\n  position: absolute;\n  background: #fff;\n  transform: rotate(30deg) skewX(-30deg) scale(1, 0.866);\n  top: calc(50% - 11px);\n  left: calc(50% - 11px);\n  z-index: 2;\n  opacity: ', ';\n  transition: opacity 0.15s ease-in-out;\n\n  &::before,\n  &::after {\n    content: \'\';\n    position: absolute;\n    background: inherit;\n    left: 0;\n    top: 0;\n  }\n\n  &,\n  &::before,\n  &::after {\n    width: ', 'px;\n    height: ', 'px;\n    border-top-right-radius: 30%;\n    backface-visibility: hidden;\n  }\n\n  &::before {\n    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)\n      translate(0, -50%);\n  }\n  &::after {\n    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);\n  }\n'], ['\n  position: absolute;\n  background: #fff;\n  transform: rotate(30deg) skewX(-30deg) scale(1, 0.866);\n  top: calc(50% - 11px);\n  left: calc(50% - 11px);\n  z-index: 2;\n  opacity: ', ';\n  transition: opacity 0.15s ease-in-out;\n\n  &::before,\n  &::after {\n    content: \'\';\n    position: absolute;\n    background: inherit;\n    left: 0;\n    top: 0;\n  }\n\n  &,\n  &::before,\n  &::after {\n    width: ', 'px;\n    height: ', 'px;\n    border-top-right-radius: 30%;\n    backface-visibility: hidden;\n  }\n\n  &::before {\n    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)\n      translate(0, -50%);\n  }\n  &::after {\n    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);\n  }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  background: #e1e8ed;\n  height: ', 'px;\n  transition: opacity 0.3s ease-in-out;\n\n  .microlink_card:not(:hover) & {\n    opacity: 0 !important;\n  }\n'], ['\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  background: #e1e8ed;\n  height: ', 'px;\n  transition: opacity 0.3s ease-in-out;\n\n  .microlink_card:not(:hover) & {\n    opacity: 0 !important;\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var PLAY_BUTTON_SIZE = 22;
var PROGRESS_BAR_HEIGHT = 2;

var getSize = function getSize(base, size) {
  return base * (size === 'normal' ? 1 : 1.75);
};

var PlayButton = exports.PlayButton = _styledComponents2.default.div(_templateObject, function (_ref) {
  var visible = _ref.visible;
  return visible ? 1 : 0;
}, function (_ref2) {
  var cardSize = _ref2.cardSize;
  return getSize(PLAY_BUTTON_SIZE, cardSize);
}, function (_ref3) {
  var cardSize = _ref3.cardSize;
  return getSize(PLAY_BUTTON_SIZE, cardSize);
});

var ProgressBar = exports.ProgressBar = _styledComponents2.default.div.attrs({
  style: function style(_ref4) {
    var playing = _ref4.playing,
        progress = _ref4.progress;
    return {
      width: progress + '%' || 0,
      opacity: playing ? 0.8 : 0
    };
  }
})(_templateObject2, function (_ref5) {
  var cardSize = _ref5.cardSize;
  return getSize(PROGRESS_BAR_HEIGHT, cardSize);
});