'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  background-image: ', ';\n'], ['\n  background-image: ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _wrap = require('./wrap');

var _wrap2 = _interopRequireDefault(_wrap);

var _utils = require('../../../utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var defaultProps = {
  className: 'microlink_card__media microlink_card__media_image'
};

exports.default = (0, _styledComponents2.default)(_wrap2.default).attrs(defaultProps)(_templateObject, function (_ref) {
  var image = _ref.image;
  return image ? 'url(\'' + (0, _utils.imageProxy)(image) + '\')' : '';
});