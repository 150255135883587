'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.imageProxy = exports.isLarge = exports.fetchFromApi = exports.fetchFromApiUrl = exports.createApiUrl = exports.defaultApiParameters = exports.media = exports.someProp = exports.getUrlPath = exports.isNil = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _templateObject = _taggedTemplateLiteral(['\n    @media (max-width: 48em) {\n      ', ';\n    }\n  '], ['\n    @media (max-width: 48em) {\n      ', ';\n    }\n  ']),
    _templateObject2 = _taggedTemplateLiteral(['\n    @media (min-width: 48em) {\n      ', ';\n    }\n  '], ['\n    @media (min-width: 48em) {\n      ', ';\n    }\n  ']);

var _styledComponents = require('styled-components');

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var REGEX_HTTPS = /^https/;
var REGEX_LOCALHOST = /http:\/\/localhost/;

var isNil = exports.isNil = function isNil(value) {
  return value == null;
};

var getUrlPath = exports.getUrlPath = function getUrlPath(data) {
  return data && (typeof data === 'undefined' ? 'undefined' : _typeof(data)) === 'object' ? data.url : data;
};

var someProp = exports.someProp = function someProp(data, props) {
  return data[props.find(function (prop) {
    return data[prop] !== null && data[prop] !== undefined;
  })];
};

var media = exports.media = {
  mobile: function mobile() {
    return (0, _styledComponents.css)(_templateObject, _styledComponents.css.apply(undefined, arguments));
  },
  desktop: function desktop() {
    return (0, _styledComponents.css)(_templateObject2, _styledComponents.css.apply(undefined, arguments));
  }
};

var apiValue = function apiValue(key, value) {
  return value === true ? '' + key : key + '=' + value;
};

var defaultApiParameters = exports.defaultApiParameters = {
  video: false,
  contrast: false,
  screenshot: false,
  prerender: 'auto'
};

var createApiUrl = exports.createApiUrl = function createApiUrl(props) {
  var apiKey = props.apiKey,
      targetUrl = props.url,
      screenshot = props.screenshot,
      prerender = props.prerender,
      contrast = props.contrast,
      video = props.video;

  var alias = apiKey ? 'pro' : 'api';
  var url = 'https://' + alias + '.microlink.io/?url=' + targetUrl;
  if (!isNil(video)) url = url + '&' + apiValue('video', video);
  if (!isNil(contrast) && contrast !== defaultApiParameters.contrast) {
    url = url + '&' + apiValue('palette', contrast);
  }
  if (!isNil(prerender) && prerender !== defaultApiParameters.prerender) {
    url = url + '&' + apiValue('prerender', prerender);
  }
  if (!isNil(screenshot) && screenshot !== defaultApiParameters.screenshot) {
    url = url + '&' + apiValue('screenshot', screenshot);
  }
  return url;
};

var fetchFromApiUrl = exports.fetchFromApiUrl = function fetchFromApiUrl(_ref) {
  var apiKey = _ref.apiKey,
      apiUrl = _ref.apiUrl;

  var headers = apiKey ? { 'x-api-key': apiKey } : {};
  return fetch(apiUrl, { headers: headers }).then(function (res) {
    return res.json();
  });
};

var fetchFromApi = exports.fetchFromApi = function fetchFromApi(props) {
  var apiUrl = createApiUrl(props);
  return fetchFromApiUrl(_extends({ apiUrl: apiUrl }, props));
};

var isLarge = exports.isLarge = function isLarge(cardSize) {
  return cardSize === 'large';
};

var imageProxy = exports.imageProxy = function imageProxy(url) {
  return REGEX_LOCALHOST.test(url) || REGEX_HTTPS.test(url) ? url : 'https://images.weserv.nl/?url=' + encodeURIComponent(url).replace('http://', '');
};