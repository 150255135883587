'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _jsx = function () { var REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol.for && Symbol.for("react.element") || 0xeac7; return function createRawReactElement(type, props, key, children) { var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = {}; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : '' + key, ref: null, props: props, _owner: null }; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  &&& {\n    text-align: inherit;\n    font-weight: inherit;\n    font-family: inherit;\n    color: inherit;\n    margin: 0;\n  }\n'], ['\n  &&& {\n    text-align: inherit;\n    font-weight: inherit;\n    font-family: inherit;\n    color: inherit;\n    margin: 0;\n  }\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _nanoclamp = require('nanoclamp');

var _nanoclamp2 = _interopRequireDefault(_nanoclamp);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var Clamp = function Clamp(_ref) {
  var children = _ref.children,
      className = _ref.className,
      lines = _ref.lines;
  return _jsx(_nanoclamp2.default, {
    className: className,
    lines: lines,
    text: children,
    is: 'p'
  });
};

var CardText = (0, _styledComponents2.default)(Clamp)(_templateObject);

exports.default = CardText;